import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './mycv.css';


function MyCV() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    return (
      
      <div className="container14">
      <br />
        <br />
        <br />
        <br />
        <div className={`menu8 ${isMenuOpen ? 'open' : ''}`}>
        <nav className="menu8-items">
          <ul>
            <li><Link to="/mi-trabajo">Mi Trabajo / My work </Link></li>
            <li><Link to="/mycv">Mi CV / My CV </Link></li>
            <br />
            <li><a href="#">Contacto / Say Hello</a></li>
            <li><a href="mailto:cristianca84@gmail.com">email</a></li>
            <li><a href="https://t.me/Cami">Telegram</a></li>
            <li><a href="https://www.linkedin.com/in/cristian-camilo-cuevas/">LinkedIn</a></li>
          </ul>
        </nav>
      </div>
        <header>
          <div className={`sidebar2 ${isMenuOpen ? 'open' : ''}`}>
            
            <div className="menu-toggle" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <nav className="menu">
              <ul>
                <li><Link to="/">Inicio / Home</Link></li>
                <li><Link to="/mi-trabajo">Mi Trabajo / My work </Link></li>
                <br />
                <li><a5 href="#">Contacto / Say Hello</a5></li>
                <li><a href="mailto:tu_correo@example.com">cristianca84@gmail.com</a></li>
                <li><a href="https://t.me/Cami">Telegram</a></li>
                <li><a href="https://www.linkedin.com/in/cristian-camilo-cuevas/">LinkedIn</a></li>
              </ul>
              
              
            </nav>
          </div>
        </header>
        <main>
        <br />
        <br />
        <br />
        <div className="container16">
      <div className="center-column">
        <br />
        <br />
        <br />
        
        <br />
        <br />
        
        <h22>Desarrollador full stack y <br/>científico de datos</h22>
        <br />
        <br />
        <h22>Master en Ingeniería del Software <br/>e Inteligencia Artificial</h22>
        <br />
        
        <br />
        
        <h33>Experiencia:</h33>

        <br />
        <br />

        <ul1>

        <li1>
            Desarrollador full Stack<br />
            <span>Image pdf to text. 2023 - presente</span>
          </li1>
          <br />
          <br />
          <li1>
            Científico de Datos Universidad<br />
            <span>de Málaga, España. 2022 - 2023</span>
          </li1>
          <br />
          <br />
          <li1>
            Asesor de Transformación Digital - <br />
            <span>Procuraduría General de la Nación. 2018 - 2021</span>
          </li1>
          <br />
          <br />
          <li1>
            Asesor de Back-End - Universidad Nacional<br />
            <span>Abierta y a Distancia. 2020 - 2022</span>
          </li1>
        </ul1>
      </div>     
      <div className="left-column">
        <div>
          <br />
          <br />
          <p11>
          <br />
          
            <a href="https://www.criscamiloportfolio.com">criscamiloportfolio.com</a>
          </p11>
          
          <p11>
          <br />
            <strong>Tunja,</strong> Colombia
          </p11>
          <br />
          <p11>
            <strong>cristianca84@gmail.com</strong> 
          </p11>
        </div>
        <br />
        <br />
        <div>
          <h23>Tecnologías</h23>
          <br />
          <br />
        
            <li2>Python</li2>
            <br />
            <li2>React</li2>
            <br />
            <li2>CSS-in-JS</li2>
            <br />
            <li2>Node.js</li2>
          
        </div>
        <div>
        <br />
        <br />
          <h23>Otros</h23>

          <br />
          <br />
          
            <li2>Diseño Web Responsivo</li2>
            <br />
            <li2>Estrategia de Interfaz de Usuario</li2>
            <br />
            <li2>Soluciones IA</li2>
            

          
        </div>
      </div>
      
    </div>
    </main>
    </div>
    );
  }
  
  export default MyCV;