// Salida.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import './Login.css'; // Importa el archivo CSS

const Salida = () => {
    return (
      <div className="salida-container"> {/* Utiliza la nueva clase específica para Salida.jsx */}
        <br />
        <br />
        <br />
        <h1>¡Gracias por utilizar Image Pdf to Text!</h1>
        <br />
        <br />
        
        <p>Esperamos que hayas tenido una excelente experiencia.</p>
        <br />
        <br />
        {/* Otro contenido si es necesario */}
        <Link to='/'>
          <button>Volver a la página de inicio</button>
        </Link>
      </div>
    );
  };
  
  export default Salida;