import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function DownloadTextPage() {
  const location = useLocation();
  const { extractedText } = location.state || {};
  const navigate = useNavigate();

  const handleDownload = () => {
    const blob = new Blob([extractedText], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'extracted_text.txt';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    // Después de la descarga exitosa, redirigir a la página principal
    navigate('/');
  };

  return (
    <div>
      <br />
      <br />
      <h1>¡Ya puedes descargar el texto extraído de tu PDF!</h1>
      <br />
      <br />
      <button onClick={handleDownload}>Descargar Texto</button>
    </div>
  );
}

export default DownloadTextPage;

