import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports.js';


import './Register.css';
import './cognito-config.js';

Amplify.configure(awsconfig);

console.log('Librería Amazon Cognito Identity:', CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails);


console.log(CognitoUser); // Añade este console.log para verificar si se importa correctamente


// Configura la información de tu grupo de usuarios
const poolData = {
  UserPoolId: awsconfig.aws_user_pools_id,
  ClientId: awsconfig.aws_user_pools_web_client_id,
};

const userPool = new CognitoUserPool(poolData);


//const BACKEND_URL = 'http://localhost:3001/api/register';

function Register() {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [emailExistsError, setEmailExistsError] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function registerUser(formData) {
    const { email, password } = formData;
  
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // Añade otros atributos personalizados si es necesario
        },
      });
      // El registro fue exitoso, muestra un mensaje de éxito y alienta al usuario a verificar su correo electrónico.
      setRegistrationSuccess(true);
    } catch (error) {
      console.error('Error de registro:', error);
      // Manejar el error, por ejemplo, mostrar un mensaje de error si el correo electrónico ya está registrado.
      setEmailExistsError(true);
    }
  }
  

  async function getUserInfo() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      console.log('Usuario actual:', user);
      console.log('Nombre de usuario:', user.username); // Aquí obtendrás el nombre de usuario
    } catch (error) {
      console.error('Error al obtener información del usuario:', error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    await registerUser(formData);
  };

  return (
    <div className="container">
      <h2>Registro</h2>
      {registrationSuccess ? (
        <div className="success-message">
          El registro ha sido exitoso. Por favor, verifica tu correo electrónico para completar el proceso.
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Correo Electrónico:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {emailExistsError && (
              <div className="error-message">
                El correo electrónico ya está registrado.
              </div>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="password">Contraseña:</label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <button type="submit" className="button">
              Registrarse
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default Register;





