import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './services.css';


function MyCV() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
  
    const toggleMenu = () => {
      setIsMenuOpen(!isMenuOpen);
    };
  
    return (
      <div className="container4">
        <div className={`menu8 ${isMenuOpen ? 'open' : ''}`}>
        <nav className="menu8-items">
          <ul>
            <li><Link to="/mi-trabajo">Mi Trabajo / My work </Link></li>
            <li><Link to="/mycv">Mi CV / My CV </Link></li>
            <br />
            <li><a href="#">Contacto / Say Hello</a></li>
            <li><a href="mailto:cristianca84@gmail.com">email</a></li>
            <li><a href="https://t.me/Cami">Telegram</a></li>
            <li><a href="https://www.linkedin.com/in/cristian-camilo-cuevas/">LinkedIn</a></li>
          </ul>
        </nav>
      </div>
        <header>
          <div className={`sidebar2 ${isMenuOpen ? 'open' : ''}`}>
            
            <div className="menu8-toggle" onClick={toggleMenu}>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
            <nav className="menu">
              <ul>
                <li><Link to="/">Inicio / Home</Link></li>
                <li><Link to="/mi-trabajo">Mi Trabajo / My work </Link></li>
                <li><Link to="/mycv">Mi CV / My CV </Link></li>
                <br />
                <li><a1 href="#">Contacto / Say Hello</a1></li>
                <li><a href="mailto:cristianca84@gmail.com">cristianca84@gmail.com</a></li>
                <li><a href="https://t.me/Cami">Telegram</a></li>
                <li><a href="https://www.linkedin.com/in/cristian-camilo-cuevas/">LinkedIn</a></li>
              </ul>
              
            </nav>
          </div>
        </header>
        <main>
        <br />
        <br />
        <div className="container6">
            
      
      <div className="center-column2">
        <br />
        <br />
        <br />
        
        <br />
        <h2>
             Desarrollador full stack <br />
            <span>y Científico de Datos</span>
        </h2>
        <h2>
            Master en Ingeniería del Software<br />
            <span>e Inteligencia Artificial</span>
        </h2>

       
        <br />
        <br />
        <br />
        <h1>Servicios:</h1>
        <ul>
          <li>Arquitectura, diseño y formulación de soluciones IA</li>
          <li>Diseño Web</li>
          <li>Construcción y diseño de Apps</li>
          <li>Adaptación de APIS</li>
        </ul>

        
      </div>

      
    </div>
    </main>
    <br />
    <br />
    <br />
    
    <footer className="bottom-menu4">
        <nav className="menu4">
          <ul>
            <li><Link to="/mi-trabajo">Mi Trabajo / My work</Link></li>
            <li><Link to="/mycv">Mi CV / My CV</Link></li>
            <li><a href="mailto:cristianca84@gmail.com">email</a></li>
            <li><a href="https://t.me/Cami">Telegram</a></li>
            <li><a href="https://www.linkedin.com/in/cristian-camilo-cuevas/">LinkedIn</a></li>
          </ul>
        </nav>
      </footer>
    
    </div>
    );
  }
  
  export default MyCV;