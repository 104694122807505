import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';


const VerificationPage = () => {
  const [redirect, setRedirect] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const verificationCode = urlParams.get('code');
    // eslint-disable-next-line
    const verificationCodeInput = document.getElementById('verification-code');
    const verificationForm = document.getElementById('verification-form');
    const messageContainer = document.getElementById('message-container');

    //verificationCodeInput.value = verificationCode || ''; // Llena el campo de código si está presente en la URL

    verificationForm.addEventListener('submit', async (event) => {
      event.preventDefault();

      // Obtiene el nombre de usuario del formulario de Cognito
      const username = getUsernameFromCognitoForm();

      if (!username) {
        displayMessage('Nombre de usuario no válido');
        console.error('Nombre de usuario no válido');
        // Manejar el error, por ejemplo, mostrar un mensaje de error al usuario.
        return;
      }

      const enteredVerificationCode = verificationCodeInput.value.trim();

      // Valida el código de verificación
      if (!validateVerificationCode(enteredVerificationCode, verificationCode)) {
        displayMessage('Código de verificación incorrecto');
        console.error('Código de verificación incorrecto');
        // Manejar el error, por ejemplo, mostrar un mensaje de error al usuario.
        return;
      }

      displayMessage('Verificando registro...');

      console.log('Nombre de usuario obtenido:', username);

      // Verifica el registro en el servidor
      await confirmRegistrationOnServer(username, verificationCode);
    });


    // Función para obtener el nombre de usuario del formulario de Cognito
    function getUsernameFromCognitoForm() {
      const emailElement = document.getElementById('cognito-email');
      return emailElement ? emailElement.value.trim() : '';
    }

    function validateVerificationCode(enteredCode, expectedCode) {
      return enteredCode === expectedCode;
    }

    function displayMessage(message) {
      if (messageContainer) {
        messageContainer.textContent = message;
      }
    }
    // Función para confirmar el registro en el servidor
    async function confirmRegistrationOnServer(username, verificationCode) {
      const poolData = {
        UserPoolId: 'us-east-1_fDoH6sE7F',
        ClientId: '17tl16o91rvf3ip956sulc2gnm',
      };

      const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

      const userData = {
        Username: username,
        Pool: userPool,
      };

      console.log('Nombre de usuario:', username);

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);

      // Ahora, intentamos confirmar el registro
      cognitoUser.confirmRegistration(verificationCode  , true, (err, result) => {
        if (err) {
          displayMessage('Error al confirmar registro: ' + err.message);
          console.error('Error al confirmar registro:', err);
          // Manejar el error, por ejemplo, mostrar un mensaje de error al usuario.
        } else {
          displayMessage('Registro confirmado con éxito');
          console.log('Registro confirmado con éxito:', result);
          setTimeout(() => {
            setRedirect(true);
            navigate('/login');
          }, 2000);
        }
      });
    }
  }, [navigate]);

  if (redirect) {
    navigate('/login');
  }

  return (
    <div>
      <h2>Verificación de Correo Electrónico</h2>
      <form id="verification-form">
        <label htmlFor="verification-code">Código de verificación:</label>
        <input type="text" id="verification-code" placeholder="Código de verificación" required />
        <br />
        <input type="hidden" id="cognito-email" value="cristianca84@gmail.com" />
        <button type="submit">Verificar</button>
      </form>
      <div id="message-container"></div>
    </div>
  );
};

export default VerificationPage;












