import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppContext } from '../AppContext';
import { Helmet } from 'react-helmet';
import './HomePage.css';
import logoImage2 from './logo_portfolio.jpeg';
import logoImage from './foto_gen.png';

function HomePage() {
  const { selectedPDF } = useAppContext();
  const navigate = useNavigate();
  const [selectedFileName, setSelectedFileName] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handlePDFSelect = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFileName(file.name);
      const fileURL = URL.createObjectURL(file);
      navigate('/services', { state: { fileURL } });
    }
  };

  return (
    <div className="containerh">
      <Helmet>
        <meta name="description" content="Portafolio de Cristian Camilo Cuevas Castañeda." />
        <link rel="icon" type="image/png" href={logoImage2} />
      </Helmet>
      {/* Menú */}
      <div className={`menu ${isMenuOpen ? 'open' : ''}`}>
        <nav className="menu-items">
          <ul>
            <li><Link to="/mi-trabajo">Mi Trabajo / My work </Link></li>
            <li><Link to="/mycv">Mi CV / My CV </Link></li>
            <br />
            <li><a href="#">Contacto / Say Hello</a></li>
            <li><a href="mailto:cristianca84@gmail.com">email</a></li>
            <li><a href="https://t.me/Cami">Telegram</a></li>
            <li><a href="https://www.linkedin.com/in/cristian-camilo-cuevas/">LinkedIn</a></li>
          </ul>
        </nav>
      </div>
      {/* Logos */}
      <div className="logo-container">
        <img src={logoImage2} alt="PDF pdf to Text Logo PDF pdf escaneado a texto extraer texto de imagen pdf" className="logo2" />
        <img src={logoImage} alt="PDF pdf to Text Logo PDF pdf escaneado a texto extraer texto de imagen pdf" className="logo" />
      </div>
      {/* Botón de menú toggle */}
      <div className="menu-toggle" onClick={toggleMenu}>
        <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
      </div>
      {/* Contenido principal */}
      <main>
        <div className="circle circle1"></div>
        <br />
        <br />
        <br />
        <h1>Cristian Camilo Cuevas Castañeda</h1>
        <br />
        <div className="file-input-container">
          <Link to="/services" className="file-input-button">
            Ve al portafolio
          </Link>
          
        </div>
      </main>
    </div>
  );
}

export default HomePage;















