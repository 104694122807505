import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import payNowdbLogo from './PayNowdb.jpeg';
import pdfToTextLogo from './pdf-escaneado-a-texto-pdf-to-text-image.jpeg';
import portfoliologo from './logo_portfolio.jpeg';
import './mitrabajo.css';


function MiTrabajo() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="container3">
      <div className={`menu8 ${isMenuOpen ? 'open' : ''}`}>
        <nav className="menu8-items">
          <ul>
            <li><Link to="/mi-trabajo">Mi Trabajo / My work </Link></li>
            <li><Link to="/mycv">Mi CV / My CV </Link></li>
            <br />
            <li><a href="#">Contacto / Say Hello</a></li>
            <li><a href="mailto:cristianca84@gmail.com">email</a></li>
            <li><a href="https://t.me/Cami">Telegram</a></li>
            <li><a href="https://www.linkedin.com/in/cristian-camilo-cuevas/">LinkedIn</a></li>
          </ul>
        </nav>
      </div>

      <header>
        <div className={`sidebar2 ${isMenuOpen ? 'open' : ''}`}>
          
          <div className="menu-toggle" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
          <nav className="menu">
            <ul>
              <li><Link to="/">Inicio / Home</Link></li>
              <li><Link to="/mycv">Mi CV / My CV </Link></li>
              <li><a5 href="#">Contacto / Say Hello</a5></li>
              <li><a href="mailto:cristianca84@gmail.com">cristianca84@gmail.com</a></li>
              <li><a href="https://t.me/Cami">Telegram</a></li>
              <li><a href="https://www.linkedin.com/in/cristian-camilo-cuevas/">LinkedIn</a></li>
            </ul>
            
          </nav>
        </div>
      </header>
      <main1>
      <div>
        <br />
        <br />
        <br />
        
      <h1></h1>
        <br />
        <br />
        
        
        <div className="logos-container">
          <div className="top-logos">
            <a href="https://www.paynowdb.com">
              <img src={payNowdbLogo} alt="PayNowdb Logo" />
            </a>
            <br />
            <br />
          <a href="https://www.imagepdftotext.com">
            <img src={pdfToTextLogo} alt="PDF to Text Logo" />
          </a>
        </div>
        <div className="center-logo">
          <a href="https://www.criscamiloportfolio.com">
            <img src={portfoliologo} alt="portfolio Logo" />
          </a>
        </div>
      </div>
    </div>
      </main1>
    </div>
  );
}

export default MiTrabajo;





    

